import { add, endOfDay, Interval, startOfDay } from 'date-fns'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'

import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog'
import { DateRangeValue } from '../../components/DateRangePicker/DateRangePicker'
import GRCircularProgress from '../../components/GRCircularProgress/GRCircularProgress'
import GRTabNavigation from '../../components/GRTabNavigation/GRTabNavigation'
import { GamePerformaceDialogData, GamePerformanceDialog } from '../../components/GamePerformanceDialog/GamePerformanceDialog'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { PerformanceChartV2DataType } from '../../components/PerformanceChartV2/PerformanceChartV2DataType'
import ShareUrlButton from '../../components/ShareUrlButton/ShareUrlButton'
import { useLiveEventsTrackerAccessCheck } from '../../features/account/hooks/roleHooks'
import { LiveEventsGameSelector } from '../../features/live-events/components/LiveEventGameSelector/LiveEventsGameSelector'
import { LiveEventInstanceTableContainer } from '../../features/live-events/components/LiveEventInstanceTable/LiveEventInstanceTableContainer'
import {
  LiveEventsCalendarByEventTypeContainer,
  calendarId as compareEventsCalendarId,
} from '../../features/live-events/components/LiveEventsCalendar/LiveEventsCalendarByEventTypeContainer/LiveEventsCalendarByEventTypeContainer'
import LiveEventsCalendarList from '../../features/live-events/components/LiveEventsCalendarList/LiveEventsCalendarList'
import { LiveEventsEventStatistics } from '../../features/live-events/components/LiveEventsEventStatistics/LiveEventsEventStatistics'
import LiveEventsFeed from '../../features/live-events/components/LiveEventsFeed/LiveEventsFeed'
import { LiveEventsFeedFilters } from '../../features/live-events/components/LiveEventsFeedFilters/LiveEventsFeedFilters'
import { LiveEventsFilters, LiveEventsFiltersConfig } from '../../features/live-events/components/LiveEventsFilters/LiveEventsFilters'
import LiveEventsGameAlertsList from '../../features/live-events/components/LiveEventsGameAlertsList/LiveEventsGameAlertsList'
import { LiveEventsViewFilters } from '../../features/live-events/components/LiveEventsViewFilters/LiveEventsViewFilters'
import {
  lockedLiveEventsTrackerStartTimestamp,
  lockedLiveEventsTrackerEndTimestamp,
  defaultTrackedEventsStartTimestamp,
} from '../../features/live-events/const/const'
import { useLiveEventsSecondaryTags } from '../../features/live-events/hooks/liveEventsSecondaryTaxonomy'
import { useLiveEventsSelectedGameIdsSetting, useUpdateLiveEventsSelectedGameIdsSetting } from '../../features/live-events/hooks/liveEventsSelectedGamesHooks'
import { EventDialogTab } from '../../features/live-events/hooks/useEventDialogTabs'
import { useLiveEventModal } from '../../features/live-events/hooks/useLiveEventModal'
import { useNonSpecialLiveEventTags } from '../../features/live-events/hooks/useLiveEventTagGroups'
import { useLiveEventsAnalystNotesModal } from '../../features/live-events/hooks/useLiveEventsAnalystNotesModal'
import { useLiveEventsAnalystOverviewModal } from '../../features/live-events/hooks/useLiveEventsAnalystOverviewModal'
import { useLiveEventsCalendarGroupClickHandler } from '../../features/live-events/hooks/useLiveEventsCalendarGroupClickHandler'
import {
  onlyMixpanelTrackingServicesToExclude,
  useLiveEventsTrackerPageAnalyticsEvents,
} from '../../features/live-events/hooks/useLiveEventsTrackerAnalyticsEvents'
import { useLiveEventsTrackingSearchParams } from '../../features/live-events/hooks/useLiveEventsTrackingSearchParams'
import { SortGamesByOptionValue } from '../../features/live-events/hooks/useSortGamesBySelectOptions'
import { useSortedTrackedGames } from '../../features/live-events/hooks/useSortedTrackedGames'
import { useTrackedGames } from '../../features/live-events/hooks/useTrackedGames'
import { useTrackedGamesCurrencies } from '../../features/live-events/hooks/useTrackedGamesCurrencies'
import { useDefaultLiveEvents, useFilteredTrackedGamesEvents } from '../../features/live-events/hooks/useTrackedGamesEvents'
import { highlightEvent, selectHighlightedEvent, selectLiveEventModalState } from '../../features/live-events/slices/liveEventModalSlice'
import {
  gameCalendarVisibilityToggled,
  maxOpenGameCalendarsCount,
  selectSelectedTrackedGames,
  selectVisibleEventTypeGroups,
  selectVisibleGameCalendars,
  toggleEventTypeGroupVisibility,
  trackedGamesSelectionChange,
  visibleGameCalendarsChanged,
} from '../../features/live-events/slices/liveEventsCalendarsSlice'
import { CalendarTimeRanges } from '../../features/live-events/types/Calendar'
import { LiveEventCalendarAdditionalDataId } from '../../features/live-events/types/LiveEventAdditionalCalendarData'
import { LiveEventAppearance } from '../../features/live-events/types/LiveEventAppearance'
import { LiveEventFeedItem, LiveEventFeedItemType } from '../../features/live-events/types/LiveEventFeedItem'
import { LiveEventTrackerTab } from '../../features/live-events/types/LiveEventTrackerTab'
import {
  PerformanceEffectTimelineItem,
  TrackingEventTimelineItem,
  LiveEventsCalendarTimelineType,
  AnalystReviewTimelineItem,
} from '../../features/live-events/types/LiveEvents'
import { TLiveEventsCommonFilters } from '../../features/live-events/types/LiveEventsCommonFilters'
import { LiveEventsGameAlert, LiveEventsGameAlertType } from '../../features/live-events/types/LiveEventsGameAlert'
import { LiveEventsTag } from '../../features/live-events/types/LiveEventsTag'
import { PerformanceChangesByOptionValue } from '../../features/live-events/types/PerformanceChangesByOption'
import { PerformanceEffectType } from '../../features/live-events/types/PerformanceEffect'
import { TrackedGame, TrackedGameMutation } from '../../features/live-events/types/TrackedGame'
import { getLiveEventCalendarAdditionalDatas, getLiveEventDurations, getLiveEventPricePoints } from '../../features/live-events/utils/utils'
import { MotivationKey, MotivationType } from '../../features/market-explorer/types/MotivationType'
import { useCurrentMarket } from '../../features/markets'
import { GranularityValue } from '../../features/revenue-and-downloads/types/Filters'
import { useFreeLiveEventsGamesSetting } from '../../features/settings'
import { getIntervalByGranularity } from '../../helpers/date'
import { dayMilliseconds } from '../../helpers/dayMilliseconds'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import analyticsService from '../../services/AnalyticsService'
import languageService from '../../services/LanguageService'
import PageService from '../../services/PageService'
import utilsService from '../../services/UtilsService'
import { BooleanMap } from '../../types/BooleanMap'
import { ConfirmDialogData } from '../../types/ConfirmDialogData'
import { LockedFeatureId } from '../../types/LockedFeature'
import './LiveEventsTrackerPage.scss'

const initialTab = LiveEventTrackerTab.Feed
export const maxDayDifferenceForDateRange = 60
export const maxLiveEventsCalendarZoomInMilliseconds = maxDayDifferenceForDateRange * dayMilliseconds // 60 days / 2 months
export const minLiveEventsCalendarZoomInMilliseconds = 7 * dayMilliseconds // 1 week
const hideWhatsNewSection = true

const LiveEventsTrackerPage: React.FC = () => {
  const { t } = useTranslation()
  usePage(PageService.getPageWithId('live-events-tracker'), 'Live Events Tracker')
  useDocumentTitle(t('sidebar:live_events_tracker'))

  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { subpage = initialTab } = useParams<{ subpage: LiveEventTrackerTab }>()
  const { currentMarketIso } = useCurrentMarket()
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const freeLiveEventsGames = useFreeLiveEventsGamesSetting()
  const liveEventsSelectedGameIds = useLiveEventsSelectedGameIdsSetting()
  const { updateLiveEventsSelectedGameIdsSetting } = useUpdateLiveEventsSelectedGameIdsSetting()
  const { setSearchParams, parsedParams } = useLiveEventsTrackingSearchParams()
  const trackedGamesQuery = useTrackedGames({})

  const selectedGames = useAppSelector(selectSelectedTrackedGames)
  const selectedGameIds = useMemo(() => selectedGames.map((trackedGame) => trackedGame.game.id), [selectedGames])
  const [eventNameSearchValue, setEventNameSearchValue] = useState<string>('')
  const highlightedEventId = useAppSelector(selectHighlightedEvent)
  const liveEventModalState = useAppSelector(selectLiveEventModalState)
  const [gamePerformanceDialogData, setGamePerformanceDialogData] = useState<GamePerformaceDialogData>()
  const { showModal: showEventModal } = useLiveEventModal()
  const { data: liveEventTagsList } = useNonSpecialLiveEventTags()
  const { showModal: showAnalystNotesModal } = useLiveEventsAnalystNotesModal()
  const { showModal: showAnalystOverviewModal } = useLiveEventsAnalystOverviewModal()
  const { data: liveEventSecondaryTagsList } = useLiveEventsSecondaryTags()
  const liveEventMotivationTypes = useMemo(() => {
    return Object.values(MotivationType)
  }, [])
  const liveEventMotivationArchetypes = useMemo(() => {
    return Object.values(MotivationKey)
  }, [])

  const [confirmRemoveGameDialogData, setConfirmRemoveGameDialogData] = useState<ConfirmDialogData<TrackedGameMutation>>()
  const trackedEvents = useDefaultLiveEvents({ trackedGameIds: selectedGameIds })
  const visibleGameCalendars = useAppSelector(selectVisibleGameCalendars)
  const visibleEventTypeGroups = useAppSelector(selectVisibleEventTypeGroups)
  const [selectedPerformanceChartInterval, setSelectedPerformanceChartInterval] = useState<Interval>()

  const selectedGameCurrencies = useTrackedGamesCurrencies({ gameIds: selectedGameIds, allTrackedGames: selectedGames })
  const trackedEventsLoading = trackedEvents.isLoading
  const isLoading = trackedGamesQuery.isLoading || trackedGamesQuery.isFetching

  const [commonFilters, setCommonFilters] = useState<TLiveEventsCommonFilters>({
    liveEventTags: [],
    liveEventSecondaryTags: [],
    additionalDatas: {},
    liveEventDurations: {},
    liveEventAppearances: {},
    liveEventPricePoints: {},
    motivations: {},
    archetypes: {},
  })

  const handleCommonFiltersChange = (filters: TLiveEventsCommonFilters) => {
    setSearchParams((currentParams) => ({
      ...currentParams,
      liveEventTags: filters.liveEventTags.map((tag) => tag.id),
      liveEventSecondaryTags: filters.liveEventSecondaryTags.map((tag) => tag.id),
      additionalDatas: Object.keys(filters.additionalDatas).map((key) => key),
      liveEventDurations: Object.keys(filters.liveEventDurations).map((key) => key),
      liveEventAppearances: Object.keys(filters.liveEventAppearances).map((key) => key),
      liveEventPricePoints: Object.keys(filters.liveEventPricePoints).map((key) => key),
      motivations: Object.keys(filters.motivations).map((key) => key),
      archetypes: Object.keys(filters.archetypes).map((key) => key),
    }))
  }

  // used to control calendar's timeline range setting to selected date range
  const [calendarTimeRanges, setCalendarTimeRanges] = useState<CalendarTimeRanges>({})
  const handleCalendarTimeRangeChange = useCallback(
    (calendarId: string | number, timeStart: number, timeEnd: number) => {
      // update time range only if it's within allowed limits
      if (timeEnd - timeStart <= maxLiveEventsCalendarZoomInMilliseconds + dayMilliseconds) {
        setCalendarTimeRanges((current) => {
          const newRanges = { ...current }
          if (newRanges[calendarId]) {
            newRanges[calendarId] = { timeStart, timeEnd }
          } else {
            newRanges[calendarId] = { timeStart: parsedParams.dateFrom, timeEnd: parsedParams.dateTo }
          }

          return newRanges
        })
      }
    },
    [parsedParams.dateFrom, parsedParams.dateTo]
  )

  const tabs = useMemo(() => {
    return Object.values(LiveEventTrackerTab).map((tab) => ({
      id: tab,
      name: t(`live-events:tab_${tab.toLowerCase()}`),
    }))
  }, [t])

  // resolve configuration for filters based on selected tab
  const filterConfig: LiveEventsFiltersConfig = useMemo(() => {
    const filtersDisabled = [LiveEventTrackerTab.Events, LiveEventTrackerTab.Statistics].includes(subpage)
    const config: LiveEventsFiltersConfig = {
      performanceChangesByDisabled: filtersDisabled,
      thresholdDisabled: filtersDisabled,
      sortGamesByDisabled: filtersDisabled,
    }

    return config
  }, [subpage])

  // filter events
  const filters = useMemo(
    () => ({
      selectedLiveEventTags: commonFilters.liveEventTags,
      selectedLiveEventSecondaryTags: commonFilters.liveEventSecondaryTags,
      selectedLiveEventsDurationIdsMap: commonFilters.liveEventDurations,
      selectedLiveEventsAppearanceIdsMap: commonFilters.liveEventAppearances,
      selectedLiveEventsPricePointIdsMap: commonFilters.liveEventPricePoints,
      selectedMotivationsIdsMap: commonFilters.motivations,
      selectedArchetypesIdsMap: commonFilters.archetypes,
      performanceEffectThreshold: parsedParams.performanceEffectThreshold,
      performanceChangesBy: parsedParams.performanceChangesBy,
      eventNameSearchValue,
    }),
    [
      commonFilters.liveEventTags,
      commonFilters.liveEventSecondaryTags,
      commonFilters.liveEventDurations,
      commonFilters.liveEventAppearances,
      commonFilters.liveEventPricePoints,
      commonFilters.motivations,
      commonFilters.archetypes,
      parsedParams.performanceEffectThreshold,
      parsedParams.performanceChangesBy,
      eventNameSearchValue,
    ]
  )

  const filteredTrackingEventsByGame = useFilteredTrackedGamesEvents({
    currencies: selectedGameCurrencies,
    data: trackedEvents,
    ...filters,
  })

  // sort selected games by selected sorting value
  const sortedSelectedGames = useSortedTrackedGames({ trackedGames: selectedGames, sortBy: parsedParams.sortGamesBy })

  const selectableLiveEventTags: LiveEventsTag[] = useMemo(() => {
    const liveEventsTagsMap: { [key: string]: boolean } = {}

    if (selectedGames.length > 0 && trackedEvents.events && Object.keys(trackedEvents.events).length > 0) {
      Object.keys(trackedEvents.events).forEach((key) => {
        trackedEvents.events[key].forEach((trackingEvent) => {
          liveEventsTagsMap[trackingEvent.typeId] = true
        })
      })
    }

    const liveEventsTags = Object.keys(liveEventsTagsMap).map((key) => {
      return { id: key, name: languageService.getTranslation('tags', key) }
    })

    return liveEventsTags.sort((a, b) => (a.name < b.name ? -1 : 1))
  }, [selectedGames.length, trackedEvents.events])

  const selectableLiveEventSecondaryTags: LiveEventsTag[] = useMemo(() => {
    const liveEventsTagsMap: { [key: string]: boolean } = {}

    if (selectedGames.length > 0 && trackedEvents.events && Object.keys(trackedEvents.events).length > 0) {
      Object.keys(trackedEvents.events).forEach((key) => {
        trackedEvents.events[key].forEach((trackingEvent) => {
          trackingEvent.tags.forEach((secondaryTagId) => {
            liveEventsTagsMap[secondaryTagId] = true
          })
        })
      })
    }

    const liveEventsTags = Object.keys(liveEventsTagsMap).map((key) => {
      return { id: key, name: languageService.getTranslation('tags', key) }
    })

    return liveEventsTags.sort((a, b) => (a.name < b.name ? -1 : 1))
  }, [selectedGames.length, trackedEvents.events])

  const selectableLiveEventMotivations: MotivationType[] = useMemo(() => {
    const motivationsIdsMap: { [key: string]: boolean } = {}

    if (selectedGames.length > 0 && trackedEvents.events && Object.keys(trackedEvents.events).length > 0) {
      Object.keys(trackedEvents.events).forEach((key) => {
        trackedEvents.events[key].forEach((trackingEvent) => {
          if (trackingEvent.motivations?.motivations) {
            trackingEvent.motivations.motivations.forEach((motivationType) => {
              motivationsIdsMap[motivationType.key] = true
            })
          }
        })
      })
    }

    const motivationTypes = Object.keys(motivationsIdsMap).map((key) => {
      return key
    })

    return motivationTypes as MotivationType[]
  }, [selectedGames.length, trackedEvents.events])

  const selectableLiveEventArchetypes: MotivationKey[] = useMemo(() => {
    const archetypesIdsMap: { [key: string]: boolean } = {}

    if (selectedGames.length > 0 && trackedEvents.events && Object.keys(trackedEvents.events).length > 0) {
      Object.keys(trackedEvents.events).forEach((key) => {
        trackedEvents.events[key].forEach((trackingEvent) => {
          if (trackingEvent.motivations?.archetypes) {
            trackingEvent.motivations.archetypes.forEach((motivationKey) => {
              archetypesIdsMap[motivationKey.key] = true
            })
          }
        })
      })
    }

    const motivationKeys = Object.keys(archetypesIdsMap).map((key) => {
      return key
    })

    return motivationKeys as MotivationKey[]
  }, [selectedGames.length, trackedEvents.events])

  const liveEventDurations = useMemo(() => {
    return getLiveEventDurations()
  }, [])

  const liveEventAppearances = useMemo(() => {
    return [LiveEventAppearance.NewEventTypes, LiveEventAppearance.RecurringEventTypes, LiveEventAppearance.NonrecurringEventTypes]
  }, [])

  const liveEventPricePoints = useMemo(() => {
    return getLiveEventPricePoints()
  }, [])

  const calendarAdditionalDatas = useMemo(() => {
    return getLiveEventCalendarAdditionalDatas()
  }, [])

  const liveEventNamesArray = useMemo(() => {
    if (!trackedEvents.events) {
      return []
    }

    const trackedEventsNameMap: { [name: string]: boolean } = {}

    Object.values(trackedEvents.events).forEach((trackingEvent) => {
      trackingEvent.forEach((event) => {
        trackedEventsNameMap[event.name] = true
      })
    })

    return Object.keys(trackedEventsNameMap).sort((a, b) => (a < b ? -1 : 1))
  }, [trackedEvents.events])

  // use games previously selected by user if url does not contain any game ids - do this only on initial load
  useEffect(() => {
    if (!liveEventsSelectedGameIds.isLoading && liveEventsSelectedGameIds.data) {
      setSearchParams((current) => ({ ...current, gameIds: current.gameIds.length > 0 ? current.gameIds : liveEventsSelectedGameIds.data || [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveEventsSelectedGameIds.isLoading, setSearchParams])

  // resolve actual tracked games from url params
  useEffect(() => {
    let games: TrackedGame[] = []

    // TODO: checking hasAccessToLiveEventsTracker is unnecesary since we only show Feature Locked Card when user does not have the needed role
    if (hasAccessToLiveEventsTracker) {
      games =
        trackedGamesQuery.data?.filter((trackedGame) => parsedParams.gameIds.includes(trackedGame.game.id)).filter((trackedGame) => trackedGame.permission) ||
        []
    } else if (freeLiveEventsGames?.currentData?.data?.free_live_events_games) {
      // Users with out live_events_tracker role are only allowed to request games that are included in FREE_LIVE_EVENTS_GAMES settings
      games =
        trackedGamesQuery.data?.filter((trackedGame) => freeLiveEventsGames?.currentData?.data?.free_live_events_games?.includes(trackedGame.game.id)) || []
    }

    dispatch(trackedGamesSelectionChange(games))
  }, [parsedParams.gameIds, trackedGamesQuery.data, hasAccessToLiveEventsTracker, freeLiveEventsGames?.currentData?.data, dispatch])

  // set event tags from url params
  useEffect(() => {
    if (liveEventTagsList) {
      let selectedLiveEventTags = liveEventTagsList.filter((liveEventTag) => parsedParams.liveEventTags.includes(liveEventTag.id)) || []

      setCommonFilters((current) => ({ ...current, liveEventTags: selectedLiveEventTags }))
    }
  }, [parsedParams.liveEventTags, liveEventTagsList])

  // set event secondary tags from url params
  useEffect(() => {
    if (liveEventSecondaryTagsList) {
      let selectedLiveEventTags = liveEventSecondaryTagsList.filter((liveEventTag) => parsedParams.liveEventSecondaryTags.includes(liveEventTag.id)) || []

      setCommonFilters((current) => ({ ...current, liveEventSecondaryTags: selectedLiveEventTags }))
    }
  }, [parsedParams.liveEventSecondaryTags, liveEventSecondaryTagsList])

  // set event motivation types from url params
  useEffect(() => {
    const selectedMotivations: MotivationType[] = liveEventMotivationTypes.filter((motivationType) => parsedParams.motivations.includes(motivationType)) || []
    const selectedMotivationsMap: BooleanMap = {}

    selectedMotivations.forEach((motivationType) => {
      selectedMotivationsMap[motivationType] = true
    })

    setCommonFilters((current) => ({ ...current, motivations: selectedMotivationsMap }))
  }, [parsedParams.motivations, liveEventMotivationTypes])

  // set event motivation archetypes from url params
  useEffect(() => {
    const selectedArchetypes: MotivationKey[] = liveEventMotivationArchetypes.filter((motivationKey) => parsedParams.archetypes.includes(motivationKey)) || []
    const selectedArchetypesMap: BooleanMap = {}

    selectedArchetypes.forEach((motivationKey) => {
      selectedArchetypesMap[motivationKey] = true
    })

    setCommonFilters((current) => ({ ...current, archetypes: selectedArchetypesMap }))
  }, [parsedParams.archetypes, liveEventMotivationArchetypes])

  // set event durations from url params
  useEffect(() => {
    if (liveEventDurations.length > 0) {
      let durations = liveEventDurations.filter((liveEventDuration) => parsedParams.liveEventDurations.includes(liveEventDuration.id)) || []

      const liveEventDurationsMap: { [durationId: string]: boolean } = {}
      durations.forEach((liveEventDuration) => {
        liveEventDurationsMap[liveEventDuration.id] = true
      })

      setCommonFilters((current) => ({ ...current, liveEventDurations: liveEventDurationsMap }))
    }
  }, [parsedParams.liveEventDurations, liveEventDurations])

  // set additional game datas from url params
  useEffect(() => {
    if (calendarAdditionalDatas.length > 0) {
      let datas = calendarAdditionalDatas.filter((data) => parsedParams.additionalDatas?.includes(data.id)) || []

      const calendarAdditionalDatasMap: { [dataId: string]: boolean } = {}
      datas.forEach((data) => {
        calendarAdditionalDatasMap[data.id] = true
      })

      setCommonFilters((current) => ({ ...current, additionalDatas: calendarAdditionalDatasMap }))
    }
  }, [parsedParams.additionalDatas, calendarAdditionalDatas])

  // set event appearances from url params
  useEffect(() => {
    if (liveEventAppearances.length > 0) {
      let appearances = liveEventAppearances.filter((appearance) => parsedParams.liveEventAppearances.includes(appearance)) || []

      const liveEventAppearancesMap: { [appearanceId: string]: boolean } = {}
      appearances.forEach((appearance) => {
        liveEventAppearancesMap[appearance] = true
      })

      setCommonFilters((current) => ({ ...current, liveEventAppearances: liveEventAppearancesMap }))
    }
  }, [parsedParams.liveEventAppearances, liveEventAppearances])

  // set event price points from url params
  useEffect(() => {
    if (liveEventPricePoints.length > 0) {
      let pricePoints = liveEventPricePoints.filter((pricePoint) => parsedParams.liveEventPricePoints.includes(pricePoint.id)) || []

      const liveEventPricePointsMap: { [pricePointId: string]: boolean } = {}
      pricePoints.forEach((pricePoint) => {
        liveEventPricePointsMap[pricePoint.id] = true
      })

      setCommonFilters((current) => ({ ...current, liveEventPricePoints: liveEventPricePointsMap }))
    }
  }, [liveEventPricePoints, parsedParams.liveEventPricePoints])

  // initially set the default date range
  useEffect(() => {
    // TODO: checking hasAccessToLiveEventsTracker is unnecesary since we only show Feature Locked Card when user does not have the needed role
    const startDate = parsedParams.dateFrom ? new Date(parsedParams.dateFrom) : startOfDay(add(new Date(), { days: -14 }))
    const initialStartDate = hasAccessToLiveEventsTracker ? startDate : new Date(lockedLiveEventsTrackerStartTimestamp)
    const endDate = parsedParams.dateTo ? new Date(parsedParams.dateTo) : endOfDay(new Date())
    const initialEndDate = hasAccessToLiveEventsTracker ? endDate : new Date(lockedLiveEventsTrackerEndTimestamp)
    setSearchParams((currentParams) => ({ ...currentParams, dateFrom: initialStartDate.getTime(), dateTo: initialEndDate.getTime() }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // initially set the default date range for feed view
  useEffect(() => {
    // TODO: checking hasAccessToLiveEventsTracker is unnecesary since we only show Feature Locked Card when user does not have the needed role
    const startDate = parsedParams.feedDateFrom ? new Date(parsedParams.feedDateFrom) : startOfDay(add(new Date(), { days: -1 }))
    const initialStartDate = hasAccessToLiveEventsTracker ? startDate : new Date(lockedLiveEventsTrackerStartTimestamp)
    const endDate = parsedParams.feedDateTo ? new Date(parsedParams.feedDateTo) : endOfDay(new Date())
    const initialEndDate = hasAccessToLiveEventsTracker ? endDate : new Date(lockedLiveEventsTrackerEndTimestamp)
    setSearchParams((currentParams) => ({ ...currentParams, feedDateFrom: initialStartDate.getTime(), feedDateTo: initialEndDate.getTime() }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set hidden calendars
  useEffect(() => {
    dispatch(visibleGameCalendarsChanged(sortedSelectedGames.map((trackedGame) => trackedGame.game.id)))
  }, [dispatch, sortedSelectedGames])

  // handle navigate and scroll to highlighted event
  useLayoutEffect(() => {
    let timeout: number
    if (liveEventModalState.meta && !isLoading && !trackedEventsLoading && parsedParams.dateFrom && parsedParams.dateTo) {
      const { trackedGameId, eventStartTime, eventType } = liveEventModalState.meta

      // navigate to Live Events Tracker Game tab if not already there
      if (subpage !== LiveEventTrackerTab.Games && subpage !== LiveEventTrackerTab.Events) {
        navigate(`/live-events-tracker/${LiveEventTrackerTab.Games}${location.search}`)
      }

      // Open the game calendar if it's not already open
      if (!visibleGameCalendars?.includes(trackedGameId || '')) {
        dispatch(gameCalendarVisibilityToggled(trackedGameId ? trackedGameId : ''))
      }

      // open the event type group if it's not already open
      if (eventType && !visibleEventTypeGroups?.includes(eventType)) {
        dispatch(toggleEventTypeGroupVisibility(eventType))
      }

      // make sure the dialogs get closed
      setGamePerformanceDialogData(undefined)

      // reset the selected performance chart interval
      setSelectedPerformanceChartInterval(undefined)

      timeout = window.setTimeout(() => {
        // scroll calendar viewport to instance
        const calendarId = subpage === LiveEventTrackerTab.Games ? trackedGameId : compareEventsCalendarId
        if (eventStartTime && calendarId) {
          const { timeStart, timeEnd } = calendarTimeRanges[calendarId] || { timeStart: parsedParams.dateFrom, timeEnd: parsedParams.dateTo }
          const delta = (timeEnd || 0) - (timeStart || 0)
          const halfDelta = Math.round(delta / 2)
          setCalendarTimeRanges((current) => {
            current[calendarId] = { timeStart: eventStartTime - halfDelta, timeEnd: eventStartTime + halfDelta }

            return { ...current }
          })
        }

        // scroll to element (middle of viewport)
        const element = liveEventModalState?.meta?.eventType
          ? document.getElementById(liveEventModalState.meta.trackedGameId + '-' + liveEventModalState.meta.eventType)
          : undefined
        const rect = element?.getBoundingClientRect()
        const topHeaderHeight = 148

        if (element) {
          window.scrollTo({ top: (rect?.top || 0) + window.scrollY - topHeaderHeight - document.documentElement.clientHeight / 2, behavior: 'smooth' })
        }

        // reset higlight state
        dispatch(highlightEvent({ id: highlightedEventId }))
      }, liveEventModalState.meta.timeout || 0)
    }

    return () => clearTimeout(timeout)
  }, [
    calendarTimeRanges,
    dispatch,
    highlightedEventId,
    liveEventModalState,
    location.search,
    navigate,
    parsedParams.dateFrom,
    parsedParams.dateTo,
    isLoading,
    trackedEventsLoading,
    visibleGameCalendars,
    location.pathname,
    visibleEventTypeGroups,
    subpage,
  ])

  const handleGameSelectionChange = useCallback(
    (games: TrackedGame[], hdGameSelected: boolean) => {
      setCommonFilters((current) => (hdGameSelected ? { ...current, liveEventTags: [] } : { ...current, liveEventPricePoints: {}, liveEventTags: [] }))
      setSearchParams((currentParams) =>
        hdGameSelected
          ? {
              ...currentParams,
              liveEventTags: [],
              liveEventSecondaryTags: [],
              motivations: [],
              archetypes: [],
              gameIds: games.map((trackedGame) => trackedGame.game.id),
            }
          : {
              ...currentParams,
              liveEventPricePoints: [],
              liveEventTags: [],
              liveEventSecondaryTags: [],
              motivations: [],
              archetypes: [],
              gameIds: games.map((trackedGame) => trackedGame.game.id),
            }
      )

      // update the selected tracked game ids to user settings, these will be reflected to the url in a useEffect hook above
      updateLiveEventsSelectedGameIdsSetting(games)
    },
    [setSearchParams, updateLiveEventsSelectedGameIdsSetting]
  )

  const handleCalendarEventItemClick = useCallback(
    (clickedItem: TrackingEventTimelineItem | PerformanceEffectTimelineItem | AnalystReviewTimelineItem) => {
      switch (clickedItem.type) {
        case LiveEventsCalendarTimelineType.TrackingEvent:
        case LiveEventsCalendarTimelineType.TrackingEventWithGame:
          const item: TrackingEventTimelineItem = clickedItem as TrackingEventTimelineItem

          showEventModal({
            trackedGameId: item.trackedGame.game.id,
            eventTypeId: item.trackingEvent.typeId,
            eventId: item.trackingEventId,
            tab: EventDialogTab.Description,
          })
          break

        case LiveEventsCalendarTimelineType.PerformanceEffect:
          const data = {
            gameName:
              (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.names['us'] ||
              (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.resolvedName,
            gameAppId: (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.appId,
            gameConventionalSubgenre: languageService.getTranslation(
              'conventionalSubgenres',
              (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.conventionalSubgenreId
            ),
            source: `${clickedItem.group} Change Click`,
          }

          analyticsService.trackEvent('Live Events Tracker: Opened Game Statistics Graph', {
            data,
            serviceToExclude: onlyMixpanelTrackingServicesToExclude,
          })

          const yAxisDataTypes = resolveYAxisDataTypes((clickedItem as PerformanceEffectTimelineItem).group)
          setSelectedPerformanceChartInterval({ start: clickedItem.start_time, end: clickedItem.end_time })
          setGamePerformanceDialogData({
            appId: (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.appId,
            gameName: (clickedItem as PerformanceEffectTimelineItem).trackedGame.game.resolvedName,
            marketIso: currentMarketIso,
            highlightedTimestamp: clickedItem.start_time,
            initialYAxisLeftConfig: { dataType: yAxisDataTypes[0] },
            initialYAxisRightConfig: { dataType: yAxisDataTypes[1] },
            initialGranularity:
              (clickedItem as PerformanceEffectTimelineItem).group === PerformanceEffectType.MAU ? GranularityValue.Month : GranularityValue.Day,
          })
          break
        case LiveEventsCalendarTimelineType.AnalystReview:
          const analystReviewItem: AnalystReviewTimelineItem = clickedItem as AnalystReviewTimelineItem
          showAnalystNotesModal({
            trackedGameId: analystReviewItem.review.gameId,
            commentId: analystReviewItem.review.id,
          })
      }
    },
    [currentMarketIso, showAnalystNotesModal, showEventModal]
  )

  const handleGameAlertCardClick = useCallback(
    (alert: LiveEventsGameAlert) => {
      switch (alert.type) {
        case LiveEventsGameAlertType.Event:
          showEventModal({
            trackedGameId: alert.gameId,
            eventId: alert.eventId || '',
            eventTypeId: alert.eventTypeId || '',
            tab: EventDialogTab.Description,
          })
          break

        case LiveEventsGameAlertType.Comment:
          showAnalystNotesModal({
            trackedGameId: alert.gameId,
            commentId: alert.commentId || '',
          })
          break
      }
    },
    [showAnalystNotesModal, showEventModal]
  )

  const handleCalendarEventGroupClick = useLiveEventsCalendarGroupClickHandler()

  // make sure selected date starts from start of day and ends to end of day
  const handleDateRangeChange = useCallback(
    (value?: DateRangeValue) => {
      let fromDate = value?.fromDate
      let toDate = value?.toDate

      // Handle fromDate and toDate setting to have maxDayDifferenceForDateRange
      if (value && value.fromDate && value.toDate) {
        const dayDifference = utilsService.getDayDifferenceFromTwoDates(value.fromDate.getTime(), value.toDate.getTime())
        if (dayDifference > maxDayDifferenceForDateRange) {
          if (new Date(parsedParams.dateFrom as number).getTime() === fromDate?.getTime() && toDate) {
            // toDate changed changed
            fromDate = new Date(toDate.getTime())
            fromDate.setHours(0, 0, 0, 0)
            fromDate.setDate(fromDate.getDate() - maxDayDifferenceForDateRange)
          } else if (new Date(parsedParams.dateTo as number)?.getTime() === toDate?.getTime() && fromDate) {
            // fromDate changed
            toDate = new Date(fromDate.getTime())
            toDate.setHours(0, 0, 0, 0)
            toDate.setDate(fromDate.getDate() + maxDayDifferenceForDateRange)
          }
        }

        if (fromDate && toDate) {
          const fixedDayDifference = utilsService.getDayDifferenceFromTwoDates(fromDate.getTime(), toDate.getTime())
          const data = {
            dayDifference: fixedDayDifference,
          }

          analyticsService.trackEvent('Live Events Tracker: Date Range Day Difference Changed', {
            data,
            serviceToExclude: onlyMixpanelTrackingServicesToExclude,
          })
        }
      }

      if (parsedParams.dateFrom !== fromDate?.getTime() || parsedParams.dateTo !== toDate?.getTime()) {
        setSearchParams((currentParams) => ({ ...currentParams, dateFrom: fromDate?.getTime(), dateTo: toDate?.getTime() }))
      }

      // every time date range is changed, report it to calendar components
      setCalendarTimeRanges((currentRanges) => {
        const newRanges = { ...currentRanges }
        Object.keys(newRanges).forEach((key) => {
          newRanges[key] = { timeStart: fromDate?.getTime(), timeEnd: toDate?.getTime() }
        })

        return newRanges
      })
    },
    [parsedParams.dateFrom, parsedParams.dateTo, setSearchParams]
  )

  // make sure selected date starts from start of day and ends to end of day for feed view
  const handleFeedDateRangeChange = useCallback(
    (value?: DateRangeValue) => {
      let fromDate = value?.fromDate
      let toDate = value?.toDate

      // Handle fromDate and toDate setting to have maxDayDifferenceForDateRange
      if (value && value.fromDate && value.toDate && fromDate && toDate) {
        const fixedDayDifference = utilsService.getDayDifferenceFromTwoDates(fromDate.getTime(), toDate.getTime())
        const data = {
          dayDifference: fixedDayDifference,
        }

        analyticsService.trackEvent('Live Events Tracker: Date Range Day Difference Changed for Feed View', {
          data,
          serviceToExclude: onlyMixpanelTrackingServicesToExclude,
        })
      }

      if (parsedParams.feedDateFrom !== fromDate?.getTime() || parsedParams.feedDateTo !== toDate?.getTime()) {
        setSearchParams((currentParams) => ({ ...currentParams, feedDateFrom: fromDate?.getTime(), feedDateTo: toDate?.getTime() }))
      }
    },
    [parsedParams.feedDateFrom, parsedParams.feedDateTo, setSearchParams]
  )

  const handleSortGamesByChange = useCallback(
    (value: SortGamesByOptionValue) => {
      setSearchParams((currentParams) => ({ ...currentParams, sortGamesBy: value }))
    },
    [setSearchParams]
  )

  const handlePerformanceEffectThresholdChange = useCallback(
    (value: number) => {
      setSearchParams((currentParams) => ({ ...currentParams, performanceEffectThreshold: value }))
    },
    [setSearchParams]
  )

  const handlePerformanceChangesByChange = useCallback(
    (value: PerformanceChangesByOptionValue) => {
      setSearchParams((currentParams) => ({ ...currentParams, performanceChangesBy: value }))
    },
    [setSearchParams]
  )

  const handleGameRemoveDialogData = useCallback(
    (trackedGame: TrackedGame, hdGameSelected: boolean) => {
      setConfirmRemoveGameDialogData({
        title: t('live-events:remove_game'),
        confirmText: <Trans i18nKey={'live-events:remove_selected_game_confirm'} values={{ gameName: trackedGame.game.name }} />,
        destructiveAction: true,
        actionText: t('common:remove'),
        data: { ...trackedGame, hdGameSelected: hdGameSelected },
      })
    },
    [t]
  )

  const handleDeselectGame = useCallback(
    (dialogData?: ConfirmDialogData<TrackedGameMutation>) => {
      if (dialogData) {
        if (dialogData.data?.hdGameSelected) {
          setSearchParams((currentParams) => ({
            ...currentParams,
            liveEventTags: [],
            liveEventSecondaryTags: [],
            motivations: [],
            archetypes: [],
            gameIds: currentParams.gameIds.filter((gameId) => gameId !== dialogData?.data?.game.id),
          }))
        } else {
          setSearchParams((currentParams) => ({
            ...currentParams,
            liveEventTags: [],
            liveEventPricePoints: [],
            liveEventSecondaryTags: [],
            motivations: [],
            archetypes: [],
            gameIds: currentParams.gameIds.filter((gameId) => gameId !== dialogData?.data?.game.id),
          }))
        }
        updateLiveEventsSelectedGameIdsSetting(selectedGames.filter((existingTrackedGame) => existingTrackedGame.game.id !== dialogData?.data?.game.id))
      }

      setConfirmRemoveGameDialogData(undefined)
    },
    [setSearchParams, updateLiveEventsSelectedGameIdsSetting, selectedGames]
  )

  const handleOverviewTrackedGameChanged = useCallback(
    (trackedGame: TrackedGame) => {
      showAnalystOverviewModal({
        trackedGameId: trackedGame.game.id,
      })
    },
    [showAnalystOverviewModal]
  )

  const handleEventNameSearchValueChange = useCallback((value: string) => {
    setEventNameSearchValue(value)
  }, [])

  const handleOpenFeedItemClick = useCallback(
    (feedItem: LiveEventFeedItem) => {
      switch (feedItem.type) {
        case LiveEventFeedItemType.LiveEvent:
          showEventModal({
            trackedGameId: feedItem.trackedGame?.game.id || '',
            eventTypeId: feedItem.trackingEvent?.typeId || '',
            eventId: feedItem.trackingEvent?.eventId || '',
            tab: EventDialogTab.Description,
          })
          break

        case LiveEventFeedItemType.AnalystNote:
          if (feedItem.analystNote && feedItem.trackedGame) {
            showAnalystNotesModal({
              trackedGameId: feedItem.trackedGame?.game.id,
              commentId: feedItem.analystNote.id,
            })
          }
          break
      }
    },
    [showAnalystNotesModal, showEventModal]
  )

  const handleChartClick = setSelectedPerformanceChartInterval

  const dateRange = useMemo(
    () => ({
      fromDate: parsedParams.dateFrom ? new Date(parsedParams.dateFrom) : undefined,
      toDate: parsedParams.dateTo ? new Date(parsedParams.dateTo) : undefined,
    }),
    [parsedParams.dateFrom, parsedParams.dateTo]
  )

  const dateRangeForFeed = useMemo(
    () => ({
      fromDate: parsedParams.feedDateFrom ? new Date(parsedParams.feedDateFrom) : undefined,
      toDate: parsedParams.feedDateTo ? new Date(parsedParams.feedDateTo) : undefined,
    }),
    [parsedParams.feedDateFrom, parsedParams.feedDateTo]
  )

  const gamePerformanceDialogSelectedUpdates = useMemo(() => {
    return selectedPerformanceChartInterval?.start ? [{ timestamp: selectedPerformanceChartInterval?.start }] : []
  }, [selectedPerformanceChartInterval?.start])

  const commonFiltersData = {
    liveEventTags: selectableLiveEventTags,
    liveEventSecondaryTags: selectableLiveEventSecondaryTags,
    additionalDatas: calendarAdditionalDatas,
    liveEventDurations: liveEventDurations,
    liveEventAppearances: liveEventAppearances,
    liveEventPricePoints: liveEventPricePoints,
    motivations: selectableLiveEventMotivations,
    archetypes: selectableLiveEventArchetypes,
  }

  useLiveEventsTrackerPageAnalyticsEvents(subpage, selectedGames, parsedParams.performanceChangesBy, eventNameSearchValue)

  const getPerformanceDialogInstanceTableInterval = useCallback(
    (granularity: GranularityValue) => {
      return selectedPerformanceChartInterval
        ? selectedPerformanceChartInterval
        : gamePerformanceDialogData?.highlightedTimestamp
        ? getIntervalByGranularity(granularity, gamePerformanceDialogData?.highlightedTimestamp)
        : undefined
    },
    [gamePerformanceDialogData?.highlightedTimestamp, selectedPerformanceChartInterval]
  )

  const handleGamePerformanceDialogOpen = useCallback(
    (trackedGame: TrackedGame) => {
      setGamePerformanceDialogData({
        appId: trackedGame.game.appId,
        gameName: trackedGame.game.resolvedName,
        marketIso: currentMarketIso,
        initialYAxisLeftConfig: { dataType: PerformanceChartV2DataType.Revenue },
        initialYAxisRightConfig: { dataType: PerformanceChartV2DataType.Downloads },
        initialGranularity: GranularityValue.Week,
      })
    },
    [currentMarketIso]
  )

  const handlePerformanceDialogClose = useCallback(() => {
    setGamePerformanceDialogData(undefined)
    setSelectedPerformanceChartInterval(undefined)
  }, [])

  const filterSelectedGame = useMemo(() => {
    return selectedGames.find((game) => game.game.appId === gamePerformanceDialogData?.appId)
  }, [gamePerformanceDialogData?.appId, selectedGames])

  return (
    <div className="LiveEventsTrackerPage">
      {hasAccessToLiveEventsTracker ? (
        <>
          <Box className="LiveEventsTrackerPage__Header" mb={2}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs>
                {t('live-events:page_description')}
              </Grid>
              <Grid item>
                <ShareUrlButton />
              </Grid>
            </Grid>
          </Box>

          <div className="LiveEventsTrackerPage__Content">
            <Card sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
              <LiveEventsGameSelector selectedGames={selectedGames} onGameSelectionChange={handleGameSelectionChange} />
              <Divider />

              {isLoading && <GRCircularProgress m={2} />}

              {liveEventTagsList && liveEventTagsList.length > 0 && sortedSelectedGames.length > 0 && (
                <CardContent>
                  <LiveEventsViewFilters
                    selectedGames={selectedGames}
                    commonFilters={commonFilters}
                    commonFiltersData={commonFiltersData}
                    eventNameSearchValue={eventNameSearchValue}
                    onFiltersChange={handleCommonFiltersChange}
                    onEventNameSearchValueChange={handleEventNameSearchValueChange}
                    liveEventNames={liveEventNamesArray}
                  />
                </CardContent>
              )}
              <Divider light />
            </Card>

            <GRTabNavigation
              tabs={tabs}
              selectedTab={tabs.findIndex((tab) => tab.id === subpage)}
              pagePrefix={'/live-events-tracker'}
              query={location.search}
              sticky={true}
              hasSecondaryStickyElement={true}
            />

            {!isLoading && selectedGames.length === 0 && (
              <Box margin={'20px'} style={{ textAlign: 'center' }}>
                <Typography>{t('live-events:no_games_selected_info')}</Typography>
              </Box>
            )}

            {isLoading ? (
              <GRCircularProgress m={2} />
            ) : (
              selectedGames.length > 0 &&
              parsedParams.dateFrom &&
              parsedParams.dateTo && (
                <>
                  {!hideWhatsNewSection && (
                    <TabContext value={subpage}>
                      <TabPanel value={LiveEventTrackerTab.Games} style={{ padding: '0px' }}>
                        <LiveEventsGameAlertsList
                          trackedGames={sortedSelectedGames}
                          trackingEventsByGame={filteredTrackingEventsByGame}
                          showComments={commonFilters.additionalDatas[LiveEventCalendarAdditionalDataId.AnalystReview] ? true : false}
                          onLiveEventsGameAlertCardClick={handleGameAlertCardClick}
                          isLoading={trackedEventsLoading}
                        />
                      </TabPanel>
                    </TabContext>
                  )}

                  <Box mt={2}>
                    {(subpage === LiveEventTrackerTab.Games || subpage === LiveEventTrackerTab.Events || subpage === LiveEventTrackerTab.Statistics) && (
                      <LiveEventsFilters
                        dateRange={dateRange}
                        minDate={defaultTrackedEventsStartTimestamp}
                        maxDate={filteredTrackingEventsByGame.maxTime}
                        sortGamesBy={parsedParams.sortGamesBy}
                        threshold={parsedParams.performanceEffectThreshold}
                        performanceChangesBy={parsedParams.performanceChangesBy}
                        onDateRangeChange={handleDateRangeChange}
                        onSortGamesByChange={handleSortGamesByChange}
                        onThresholdChange={handlePerformanceEffectThresholdChange}
                        onPerformanceChangesByChange={handlePerformanceChangesByChange}
                        config={filterConfig}
                      />
                    )}

                    {subpage === LiveEventTrackerTab.Feed && (
                      <LiveEventsFeedFilters
                        dateRange={dateRangeForFeed}
                        minDate={defaultTrackedEventsStartTimestamp}
                        maxDate={filteredTrackingEventsByGame.maxTime}
                        onDateRangeChange={handleFeedDateRangeChange}
                      />
                    )}
                  </Box>

                  <TabContext value={subpage}>
                    <TabPanel value={LiveEventTrackerTab.Feed}>
                      <LiveEventsFeed
                        trackedGames={sortedSelectedGames}
                        trackingEventsByGame={filteredTrackingEventsByGame}
                        dateRange={dateRangeForFeed}
                        isLoading={trackedEventsLoading}
                        selectedCalendarAdditionalDataIdsMap={commonFilters.additionalDatas}
                        onOpenFeedItemClick={handleOpenFeedItemClick}
                      />
                    </TabPanel>
                    <TabPanel value={LiveEventTrackerTab.Games}>
                      <LiveEventsCalendarList
                        trackedGames={sortedSelectedGames}
                        trackingEventsByGame={filteredTrackingEventsByGame}
                        trackingAllEventsByGame={trackedEvents.events}
                        calendarTimeRanges={calendarTimeRanges}
                        onEventItemClick={handleCalendarEventItemClick}
                        onEventGroupClick={handleCalendarEventGroupClick}
                        onDeselectGame={handleGameRemoveDialogData}
                        onOverviewTrackedGameChanged={handleOverviewTrackedGameChanged}
                        timeStart={parsedParams.dateFrom}
                        timeEnd={parsedParams.dateTo}
                        isLoading={trackedEventsLoading}
                        highlightedEventId={highlightedEventId}
                        onCalendarTimeRangeChange={handleCalendarTimeRangeChange}
                        selectedCalendarAdditionalDataIdsMap={commonFilters.additionalDatas}
                        minZoom={minLiveEventsCalendarZoomInMilliseconds}
                        maxZoom={maxLiveEventsCalendarZoomInMilliseconds}
                        onGamePerformanceDialogOpen={handleGamePerformanceDialogOpen}
                      />
                    </TabPanel>
                    <TabPanel value={LiveEventTrackerTab.Events}>
                      {sortedSelectedGames.length > maxOpenGameCalendarsCount && (
                        <Box mb={1}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Info color="action" />
                            </Grid>
                            <Grid item xs>
                              <Typography color="action" variant="body1">
                                {t('live-events:compare_events_over_3_games_usability_instructions')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}

                      <LiveEventsCalendarByEventTypeContainer
                        trackedGames={sortedSelectedGames}
                        trackingEventsByGame={filteredTrackingEventsByGame}
                        trackingAllEventsByGame={trackedEvents.events}
                        calendarTimeRanges={calendarTimeRanges}
                        onItemClick={handleCalendarEventItemClick}
                        onGroupClick={handleCalendarEventGroupClick}
                        timeStart={parsedParams.dateFrom}
                        timeEnd={parsedParams.dateTo}
                        isLoading={trackedEventsLoading}
                        highlightedEventId={highlightedEventId}
                        onCalendarTimeRangeChange={handleCalendarTimeRangeChange}
                        minZoom={minLiveEventsCalendarZoomInMilliseconds}
                        maxZoom={maxLiveEventsCalendarZoomInMilliseconds}
                      />
                    </TabPanel>
                    <TabPanel value={LiveEventTrackerTab.Statistics}>
                      <LiveEventsEventStatistics
                        trackedGames={sortedSelectedGames}
                        trackingEventsByGame={filteredTrackingEventsByGame}
                        timeStart={parsedParams.dateFrom}
                        timeEnd={parsedParams.dateTo}
                        isLoading={trackedEventsLoading}
                      />
                    </TabPanel>
                  </TabContext>
                </>
              )
            )}

            {gamePerformanceDialogData && (
              <GamePerformanceDialog
                open={!!gamePerformanceDialogData}
                onClose={handlePerformanceDialogClose}
                appId={gamePerformanceDialogData!.appId}
                marketIso={gamePerformanceDialogData!.marketIso}
                gameName={gamePerformanceDialogData!.gameName}
                selectedVerticalMarks={gamePerformanceDialogSelectedUpdates}
                initialYAxisLeftConfig={gamePerformanceDialogData.initialYAxisLeftConfig}
                initialYAxisRightConfig={gamePerformanceDialogData.initialYAxisRightConfig}
                initialGranularity={gamePerformanceDialogData.initialGranularity}
                onChartClick={handleChartClick}
                selectedGame={filterSelectedGame}
                render={(granularity: GranularityValue) => {
                  return (
                    <LiveEventInstanceTableContainer
                      interval={getPerformanceDialogInstanceTableInterval(granularity)}
                      events={trackedEvents.events}
                      trackedGames={trackedGamesQuery.data}
                      appId={gamePerformanceDialogData.appId}
                      isLoading={trackedGamesQuery.isFetching || trackedEventsLoading}
                    />
                  )
                }}
              ></GamePerformanceDialog>
            )}
          </div>
        </>
      ) : (
        <LockedFeature.Card lockedFeatureId={LockedFeatureId.LiveEventsTracker} />
      )}

      <ConfirmDialog open={!!confirmRemoveGameDialogData} confirmDialogData={confirmRemoveGameDialogData} onClose={handleDeselectGame} />
    </div>
  )
}

export default LiveEventsTrackerPage

export const resolveYAxisDataTypes = (value: PerformanceEffectType): [PerformanceChartV2DataType, PerformanceChartV2DataType] => {
  switch (value) {
    case PerformanceEffectType.DAU:
      return [PerformanceChartV2DataType.DAU, PerformanceChartV2DataType.Revenue]
    case PerformanceEffectType.MAU:
      return [PerformanceChartV2DataType.MAU, PerformanceChartV2DataType.Revenue]
    default:
      return [PerformanceChartV2DataType.Revenue, PerformanceChartV2DataType.Downloads]
  }
}
